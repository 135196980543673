import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'


import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import './assets/styles.css';

const store = createStore({
  state () {
    let  base_url = "https://acttiv.smileandlearn.com"
    return {
      lang: 'es',
      smilecontents:[
        {
          id:1,
          section_title:{
            es:"Escape Room",
            en:"Escape Room",
            fr:"Escape Room",
            de:"Escape Room"
          },
          contents:[
            {
              id:"SCAPEJAGUAR",
              title:{
                es:"El templo del Jaguar",
                en:"The Jaguar Temple",
                fr:"Le temple du Jaguar",
                de:"Der Tempel des Jaguars"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SCAPEJAGUAR.png",
              url:{
                es: base_url+"/games/SCAPEJAGUAR/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SCAPEJAGUAR/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SCAPEJAGUAR/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SCAPEJAGUAR/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"SCAPELABORATORY",
              title:{
                es:"El laboratorio",
                en:"The Lab",
                fr:"Le laboratoire",
                de:"Das Labor"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SCAPELABORATORY.png",
              url:{
                es: base_url+"/games/SCAPELABORATORY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SCAPELABORATORY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SCAPELABORATORY/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SCAPELABORATORY/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"SCAPECASTLE",
              title:{
                es:"Los misterios del castillo",
                en:"The Mysteries of the Castle",
                fr:"Les mystères du château",
                de:"Die Geheimnisse des Schlosses"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SCAPECASTLE.png",
              url:{
                es: base_url+"/games/SCAPECASTLE/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SCAPECASTLE/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SCAPECASTLE/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SCAPECASTLE/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            }
          ]
        },
        {
          id:2,
          section_title:{
            es:"Protege el medio ambiente",
            en:"Protect the Environment",
            fr:"Protège l'environnement",
            de:"Schütze die Umwelt"
          },
          contents:[
            {
              id:"RECYCLING",
              title:{
                es:"Reciclaje",
                en:"Recycling",
                fr:"Recyclage",
                de:"Recycling"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/RECYCLING.png",
              url:{
                es: base_url+"/games/RECYCLING/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/RECYCLING/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/RECYCLING/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/RECYCLING/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"ECOLOGICAL_GUARDIAN_VIDEO",
              title:{
                es:"Detective ecológico",
                en:"The Eco-Detective",
                fr:"Détective écologique",
                de:"Umweltdetektiv"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ECOLOGICAL_GUARDIAN.png",
              url:{
                es: base_url+"/videos/Detective_Ecologico_ES.mp4",
                en: base_url+"/videos/Detective_Ecologico_EN.mp4",
                fr: base_url+"/videos/Detective_Ecologico_FR.mp4",
                de: base_url+"/videos/Detective_Ecologico_DE.mp4",
              }
            },
            {
              id:"WATERSAVING_VIDEO",
              title:{
                es:"Ahorro de agua",
                en:"Saving Water",
                fr:"Les économies d'eau",
                de:"Wasser sparen"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/WATERSAVING.png",
              url:{
                es: base_url+"/videos/Ahorro_Agua_ES.mp4",
                en: base_url+"/videos/Ahorro_Agua_EN.mp4",
                fr: base_url+"/videos/Ahorro_Agua_FR.mp4",
                de: base_url+"/videos/Ahorro_Agua_DE.mp4",
              }
            }
          ]
        },
        {
          id:3,
          section_title:{
            es:"Pintar",
            en:"Paint",
            fr:"Peindre",
            de:"Malerei"
          },
          contents:[
            {
              id:"MUSEUMABSTRACT",
              title:{
                es:"Museo Abstracto",
                en:"Abstract Art Museum",
                fr:"Musée abstrait",
                de:"Abstraktes Museum"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/MUSEUMABSTRACT.png",
              url:{
                es: base_url+"/games/MUSEUMABSTRACT/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/MUSEUMABSTRACT/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/MUSEUMABSTRACT/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/MUSEUMABSTRACT/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"DRAW_FACE_VIDEO",
              title:{
                es:"Dibujar rostros",
                en:"Drawing Portraits",
                fr:"Dessiner des visages",
                de:"Porträtzeichnung"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/DRAW_FACE.png",
              url:{
                es: base_url+"/videos/Dibujar_Rostros_ES.mp4",
                en: base_url+"/videos/Dibujar_Rostros_EN.mp4",
                fr: base_url+"/videos/Dibujar_Rostros_FR.mp4",
                de: base_url+"/videos/Dibujar_Rostros_DE.mp4",
              }
            }
          ]
        },
        {
          id:4,
          section_title:{
            es:"Atención y memoria",
            en:"Attention and Memory",
            fr:"Attention et mémoire",
            de:"Aufmerksamkeit und Gedächtnis"
          },
          contents:[
            {
              id:"SIMON",
              title:{
                es:"Simon",
                en:"Repeat After Me",
                fr:"Jacques a dit",
                de:"Sprich mir nach"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SIMON.png",
              url:{
                es: base_url+"/games/SIMON/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SIMON/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SIMON/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SIMON/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"ATTENTION_EUROPE_FLAGS_VIDEO",
              title:{
                es:"Europa",
                en:"Europe",
                fr:"L'Europe",
                de:"Europa"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTION_EUROPE_FLAGS.png",
              url:{
                es: base_url+"/videos/Atencion_Banderas_Europa_ES.mp4",
                en: base_url+"/videos/Atencion_Banderas_Europa_EN.mp4",
                fr: base_url+"/videos/Atencion_Banderas_Europa_FR.mp4",
                de: base_url+"/videos/Atencion_Banderas_Europa_DE.mp4",
              }
            },
            {
              id:"ATTENTIONEUROPEFLAGS2_VIDEO",
              title:{
                es:"Europa 2",
                en:"Flags of Europe 2",
                fr:"Europe 2",
                de:"Europa 2"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTIONEUROPEFLAGS2_VIDEO.png",
              url:{
                es: base_url+"/videos/ATTENTIONEUROPEFLAGS2_ES.mp4",
                en: base_url+"/videos/ATTENTIONEUROPEFLAGS2_EN.mp4",
                fr: base_url+"/videos/ATTENTIONEUROPEFLAGS2_FR.mp4",
                de: base_url+"/videos/ATTENTIONEUROPEFLAGS2_DE.mp4",
              }
            },
            {
              id:"ATTENTIONLATAMFLAGS_VIDEO",
              title:{
                es:"Latinoamérica",
                en:"Flags of Latin America",
                fr:"Amérique latine",
                de:"Lateinamerika"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTIONLATAMFLAGS_VIDEO.png",
              url:{
                es: base_url+"/videos/ATTENTIONLATAMFLAGS_ES.mp4",
                en: base_url+"/videos/ATTENTIONLATAMFLAGS_EN.mp4",
                fr: base_url+"/videos/ATTENTIONLATAMFLAGS_FR.mp4",
                de: base_url+"/videos/ATTENTIONLATAMFLAGS_DE.mp4",
              }
            },
            {
              id:"ATTENTIONAFRICAFLAGS_VIDEO",
              title:{
                es:"África",
                en:"Flags of Africa",
                fr:"Afrique",
                de:"Afrika"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTIONAFRICAFLAGS_VIDEO.png",
              url:{
                es: base_url+"/videos/ATTENTIONAFRICAFLAGS_ES.mp4",
                en: base_url+"/videos/ATTENTIONAFRICAFLAGS_EN.mp4",
                fr: base_url+"/videos/ATTENTIONAFRICAFLAGS_FR.mp4",
                de: base_url+"/videos/ATTENTIONAFRICAFLAGS_DE.mp4",
              }
            },
            {
              id:"ATTENTIONASIAFLAGS_VIDEO",
              title:{
                es:"Asia",
                en:"Flags of Asia",
                fr:"Asie",
                de:"Asien"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTIONASIAFLAGS_VIDEO.png",
              url:{
                es: base_url+"/videos/ATTENTIONASIAFLAGS_ES.mp4",
                en: base_url+"/videos/ATTENTIONASIAFLAGS_EN.mp4",
                fr: base_url+"/videos/ATTENTIONASIAFLAGS_FR.mp4",
                de: base_url+"/videos/ATTENTIONASIAFLAGS_DE.mp4",
              }
            },
            {
              id:"ATTENTIONOTHERFLAGS_VIDEO",
              title:{
                es:"Banderas del Mundo",
                en:"Flags of the World",
                fr:"Drapeaux du monde",
                de:"Flaggen der Welt"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ATTENTIONOTHERFLAGS_VIDEO.png",
              url:{
                es: base_url+"/videos/ATTENTIONOTHERFLAGS_ES.mp4",
                en: base_url+"/videos/ATTENTIONOTHERFLAGS_EN.mp4",
                fr: base_url+"/videos/ATTENTIONOTHERFLAGS_FR.mp4",
                de: base_url+"/videos/ATTENTIONOTHERFLAGS_DE.mp4",
              }
            },
            {
              id:"CURIOUSFLAGS_VIDEO",
              title:{
                es:"Banderas Curiosas",
                en:"Curious Flags",
                fr:"Drapeaux curieux",
                de:"Kuriose Flaggen"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/CURIOUSFLAGS_VIDEO.png",
              url:{
                es: base_url+"/videos/CURIOUSFLAGS_ES.mp4",
                en: base_url+"/videos/CURIOUSFLAGS_EN.mp4",
                fr: base_url+"/videos/CURIOUSFLAGS_FR.mp4",
                de: base_url+"/videos/CURIOUSFLAGS_DE.mp4",
              }
            }
          ]
        },
        {
          id:5,
          section_title:{
            es:"Pasatiempos",
            en:"Puzzles",
            fr:"Pasee-temps",
            de:"Rätsel"
          },
          contents:[
            {
              id:"SUDOKUSHAPE",
              title:{
                es:"Sudoku formas",
                en:"Shapes Sudoku",
                fr:"Formes Sudoku",
                de:"Formen Sudoku"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SUDOKUSHAPE.png",
              url:{
                es: base_url+"/games/SUDOKUSHAPE/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SUDOKUSHAPE/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SUDOKUSHAPE/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SUDOKUSHAPE/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            }
          ]
        },
        {
          id:6,
          section_title:{
            es:"Juego en equipo",
            en:"Team Game",
            fr:"Jeu d'équipe",
            de:"Teamspiel"
          },
          contents:[
            {
              id:"CONNECT",
              title:{
                es:"Conecta Caras",
                en:"Connect Faces",
                fr:"Relie les pions",
                de:"Gesichter verbinden"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/CONNECT.png",
              url:{
                es: base_url+"/games/CONNECT/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/CONNECT/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/CONNECT/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/CONNECT/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            }
          ]
        },
        {
          id:7,
          section_title:{
            es:"Actividad física",
            en:"Physical Activity",
            fr:"L'activité physique",
            de:"sportliche Aktivität"
          },
          contents:[
            {
              id:"SPORTCHALLENGES",
              title:{
                es:"Reto deportivo",
                en:"Sports Challenge",
                fr:"Défi sportif",
                de:"Sportliche Herausforderung"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/SPORTCHALLENGES.png",
              url:{
                es: base_url+"/games/SPORTCHALLENGES/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/SPORTCHALLENGES/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/SPORTCHALLENGES/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/SPORTCHALLENGES/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            }
          ]
        },
        {
          id:8,
          section_title:{
            es:"Relajación",
            en:"Relaxation",
            fr:"La relaxation",
            de:"Entspannung"
          },
          contents:[
            {
              id:"SUNSALUTATION_VIDEO",
              title:{
                es:"El saludo al sol",
                en:"Sun Salutation",
                fr:"La salutation au soleil",
                de:"der Sonnengruß"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/SUNSALUTATION.png",
              url:{
                es: base_url+"/videos/YogaSaludoSol_ES.mp4",
                en: base_url+"/videos/YogaSaludoSol_EN.mp4",
                fr: base_url+"/videos/YogaSaludoSol_FR.mp4",
                de: base_url+"/videos/YogaSaludoSol_DE.mp4",
              }
            },
            {
              id:"YOGA_VIDEO",
              title:{
                es:"Yoga con animales",
                en:"Yoga with Animals",
                fr:"Yoga avec des animaux",
                de:"Yoga für Kinder"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/YOGA.png",
              url:{
                es: base_url+"/videos/YogaAnimales_ES.mp4",
                en: base_url+"/videos/YogaAnimales_EN.mp4",
                fr: base_url+"/videos/YogaAnimales_FR.mp4",
                de: base_url+"/videos/YogaAnimales_DE.mp4",
              }
            }
          ]
        },
        {
          id:9,
          section_title:{
            es:"Puzles",
            en:"Puzzles",
            fr:"Puzzles",
            de:"Puzzles"
          },
          contents:[
            {
              id:"TANGRAM",
              title:{
                es:"Tangram",
                en:"Tangram",
                fr:"Tangram",
                de:"Tangram"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/TANGRAM.png",
              url:{
                es: base_url+"/games/TANGRAM/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/TANGRAM/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/TANGRAM/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/TANGRAM/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            }
          ]
        },
        {
          id:10,
          section_title:{
            es:"Animales",
            en:"Animals",
            fr:"Les animaux",
            de:"Tiere"
          },
          contents:[
            {
              id:"ANIMALSSHARK_VIDEO",
              title:{
                es:"El tiburón",
                en:"Sharks",
                fr:"Le requin",
                de:"Der Hai"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ANIMALSSHARK.png",
              url:{
                es: base_url+"/videos/Tiburon_ES.mp4",
                en: base_url+"/videos/Tiburon_EN.mp4",
                fr: base_url+"/videos/Tiburon_FR.mp4",
                de: base_url+"/videos/Tiburon_DE.mp4",
              }
            },
            {
              id:"ECHINODERMS_VIDEO",
              title:{
                es:"Equinodermos y esponjas",
                en:"Echinoderms and Sponges",
                fr:"Les échinodermes et les éponges",
                de:"Stachelhäuter und Schwämme"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ECHINODERMS.png",
              url:{
                es: base_url+"/videos/Equinodermos_ES.mp4",
                en: base_url+"/videos/Equinodermos_EN.mp4",
                fr: base_url+"/videos/Equinodermos_FR.mp4",
                de: base_url+"/videos/Equinodermos_DE.mp4",
              }
            },
            {
              id:"HABITATS_AQUATIC_VIDEO",
              title:{
                es:"Habitats acuáticos",
                en:"Aquatic Habitats",
                fr:"Les habitats aquatiques",
                de:"Aquatische Lebensräume"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/HABITATS_AQUATIC.png",
              url:{
                es: base_url+"/videos/Habitats_Acuaticos_ES.mp4",
                en: base_url+"/videos/Habitats_Acuaticos_EN.mp4",
                fr: base_url+"/videos/Habitats_Acuaticos_FR.mp4",
                de: base_url+"/videos/Habitats_Acuaticos_DE.mp4",
              }
            }
          ]
        },
        {
          id:11,
          section_title:{
            es:"Dinosaurios",
            en:"Dinosaurs",
            fr:"Les dinosaures",
            de:"Dinosaurier"
          },
          contents:[
            {
              id:"CURIOUSDINOSAURS_VIDEO",
              title:{
                es:"Dinosaurios curiosos",
                en:"Peculiar Dinosaurs",
                fr:"Dinosaures curieux",
                de:"Seltsame Dinosaurier"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/CURIOUSDINOSAURS.png",
              url:{
                es: base_url+"/videos/Dinosaurios_3_Curiosos_ES.mp4",
                en: base_url+"/videos/Dinosaurios_3_Curiosos_EN.mp4",
                fr: base_url+"/videos/Dinosaurios_3_Curiosos_FR.mp4",
                de: base_url+"/videos/Dinosaurios_3_Curiosos_DE.mp4",
              }
            }
          ]
        },
        {
          id:5,
          section_title:{
            es:"Varios",
            en:"Miscellaneous",
            fr:"Contenu varié",
            de:"Sonstige"
          },
          contents:[
            {
              id:"REFLEXES",
              title:{
                es:"Atención... TAS!",
                en:"Watch... GOTCHA!",
                fr:"Attention...PAF!",
                de:"Achtung... HAB DICH!"
              },
              type: 'app',
              thumbnail: base_url+"/web/Thumbnails/REFLEXES.png",
              url:{
                es: base_url+"/games/REFLEXES/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: base_url+"/games/REFLEXES/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                fr: base_url+"/games/REFLEXES/index.html?l4ngs=uJlTZjVEABtEqO4yQdpPJA==",
                de: base_url+"/games/REFLEXES/index.html?l4ngs=YpQODtTCbqZJ7GB6og6P0w==",
              }
            },
            {
              id:"WATER_ANIMALS_VIDEO",
              title:{
                es:"Los animales del mar",
                en:"Sea Animals",
                fr:"Les animaux de la mer",
                de:"Meerestiere"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/WATER_ANIMALS.png",
              url:{
                es: base_url+"/videos/Animales_Agua_Vocabulario_ES.mp4",
                en: base_url+"/videos/Animales_Agua_Vocabulario_EN.mp4",
                fr: base_url+"/videos/Animales_Agua_Vocabulario_FR.mp4",
                de: base_url+"/videos/Animales_Agua_Vocabulario_DE.mp4",
              }
            },
            {
              id:"BUILDTREE_VIDEO",
              title:{
                es:"Autoestima - Crea tu árbol",
                en:"Build Your Tree",
                fr:"L'amour propre - Crée ton arbre",
                de:"Erschaffe deinen Baum"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/BUILDTREE.png",
              url:{
                es: base_url+"/videos/ArbolCualidades_ES.mp4",
                en: base_url+"/videos/ArbolCualidades_EN.mp4",
                fr: base_url+"/videos/ArbolCualidades_FR.mp4",
                de: base_url+"/videos/ArbolCualidades_DE.mp4",
              }
            },
            {
              id:"PERCUSSIONINSTRUMENTS_VIDEO",
              title:{
                es:"Instrumentos de percusión",
                en:"Percussion Instruments",
                fr:"Les instruments à percussion",
                de:"Schlaginstrumente"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/PERCUSSIONINSTRUMENTS.png",
              url:{
                es: base_url+"/videos/Instrumentos_Percusion_ES.mp4",
                en: base_url+"/videos/Instrumentos_Percusion_EN.mp4",
                fr: base_url+"/videos/Instrumentos_Percusion_FR.mp4",
                de: base_url+"/videos/Instrumentos_Percusion_DE.mp4",
              }
            },
            {
              id:"MEMORYSPACEI_VIDEO",
              title:{
                es:"Memoria visual",
                en:"Visual Memory I",
                fr:"La mémoire visuelle",
                de:"Visuelles Gedächtnis II"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/MEMORYSPACEI.png",
              url:{
                es: base_url+"/videos/Memoria_Espacio_I_ES.mp4",
                en: base_url+"/videos/Memoria_Espacio_I_EN.mp4",
                fr: base_url+"/videos/Memoria_Espacio_I_FR.mp4",
                de: base_url+"/videos/Memoria_Espacio_I_DE.mp4",
              }
            },
            {
              id:"REEFCUP_VIDEO",
              title:{
                es:"Copa Arrecife",
                en:"The Reef Cup",
                fr:"La Coupe Récif",
                de:"Riff Cup"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/REEFCUP.png",
              url:{
                es: base_url+"/videos/Copa_Arrecife_ES.mp4",
                en: base_url+"/videos/Copa_Arrecife_EN.mp4",
                fr: base_url+"/videos/Copa_Arrecife_FR.mp4",
                de: base_url+"/videos/Copa_Arrecife_DE.mp4",
              }
            },
            {
              id:"GOLDENCOIN_VIDEO",
              title:{
                es:"Moneda de Oro",
                en:"The Golden Coin",
                fr:"La pièce d'or",
                de:"Die Goldene Münze"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/GOLDENCOIN.png",
              url:{
                es: base_url+"/videos/Moneda_de_Oro_ES.mp4",
                en: base_url+"/videos/Moneda_de_Oro_EN.mp4",
                fr: base_url+"/videos/Moneda_de_Oro_FR.mp4",
                de: base_url+"/videos/Moneda_de_Oro_DE.mp4",
              }
            },
            {
              id:"DRAGON_VIDEO",
              title:{
                es:"Dragón de Aguazul",
                en:"The Calamead Dragon",
                fr:"Le Dragon de Flotbleu",
                de:"Der Drache von Blauensee"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/DRAGON.png",
              url:{
                es: base_url+"/videos/Dragon_ES.mp4",
                en: base_url+"/videos/Dragon_EN.mp4",
                fr: base_url+"/videos/Dragon_FR.mp4",
                de: base_url+"/videos/Dragon_DE.mp4",
              }
            },
            {
              id:"RESPONSIBLECONSUMPTION_VIDEO",
              title:{
                es:"Consumo responsable",
                en:"Responsible Consumption",
                fr:"La consommation responsable",
                de:"Verantwortungsvoller Konsum"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/RESPONSIBLECONSUMPTION.png",
              url:{
                es: base_url+"/videos/Consumo_Responsable_ES.mp4",
                en: base_url+"/videos/Consumo_Responsable_EN.mp4",
                fr: base_url+"/videos/Consumo_Responsable_FR.mp4",
                de: base_url+"/videos/Consumo_Responsable_DE.mp4",
              }
            },
            {
              id:"ENVIRONMENT_VIDEO",
              title:{
                es:"Cómo cuidar el medio ambiente",
                en:"The Environment",
                fr:"Comment prendre soin de l’environnement",
                de:"Die Umwelt"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/ENVIRONMENT.png",
              url:{
                es: base_url+"/videos/Medio_Ambiente_ES.mp4",
                en: base_url+"/videos/Medio_Ambiente_EN.mp4",
                fr: base_url+"/videos/Medio_Ambiente_FR.mp4",
                de: base_url+"/videos/Medio_Ambiente_DE.mp4",
              }
            },
            {
              id:"SOUND_VIDEO",
              title:{
                es:"El sonido",
                en:"Sound",
                fr:"Le son",
                de:"der Ton"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/SOUND.png",
              url:{
                es: base_url+"/videos/SOUND_ES.mp4",
                en: base_url+"/videos/SOUND_EN.mp4",
                fr: base_url+"/videos/SOUND_FR.mp4",
                de: base_url+"/videos/SOUND_DE.mp4",
              }
            },
            {
              id:"SUN_VIDEO",
              title:{
                es:"El Sol",
                en:"The Sun",
                fr:"Le Soleil",
                de:"Die Sonne"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/SUN.png",
              url:{
                es: base_url+"/videos/Sol_ES.mp4",
                en: base_url+"/videos/Sol_EN.mp4",
                fr: base_url+"/videos/Sol_FR.mp4",
                de: base_url+"/videos/Sol_DE.mp4",
              }
            },
            {
              id:"MOON_VIDEO",
              title:{
                es:"La Luna",
                en:"The Moon",
                fr:"La Lune",
                de:"Der Mond"
              },
              type: 'video',
              thumbnail: base_url+"/web/Thumbnails/MOON.png",
              url:{
                es: base_url+"/videos/Luna_ES.mp4",
                en: base_url+"/videos/Luna_EN.mp4",
                fr: base_url+"/videos/Luna_FR.mp4",
                de: base_url+"/videos/Luna_DE.mp4",
              }
            }
          ]
        }
        
      ]
    }
  },
  mutations: {
    changeLang (state, lang) {
      state.lang = lang
    }
  },
  getters: {
    smilecontents: state => state.smilecontents,
  }
})
const app = createApp(App);
app.use(store)
app.use(Antd).mount("#app");






