<template>
  <main-layout></main-layout>
</template>

<script>
import MainLayout from './components/MainLayout.vue'
export default {
  name: 'App',
  components: {
    MainLayout
  }
}
</script>

<style>

</style>
