<template>
  <div>
    <div class="layout-langs">
      <span :class="['pointer', $store.state.lang == 'es' ? 'selected-acttiv-lang': 'acttiv-lang']" @click="$store.commit('changeLang', 'es')">ES</span> | 
      <span :class="['pointer', $store.state.lang == 'en' ? 'selected-acttiv-lang': 'acttiv-lang']" @click="$store.commit('changeLang', 'en')">EN</span> | 
      <span :class="['pointer', $store.state.lang == 'fr' ? 'selected-acttiv-lang': 'acttiv-lang']" @click="$store.commit('changeLang', 'fr')">FR</span> | 
      <span :class="['pointer', $store.state.lang == 'de' ? 'selected-acttiv-lang': 'acttiv-lang']" @click="$store.commit('changeLang', 'de')">DE</span> 
    </div>
    <div>
      <main-list />
    </div>
    <div class="layout-footer">Acttiv© - Created by Smile and Learn</div>
  </div>
</template>
<script>

import { defineComponent, ref } from 'vue';
import MainList from './MainList.vue';
export default defineComponent({
  components:{
     MainList
  },
  setup() {
    return {
      selectedKeys: ref(['2']),
    };
  },
  data(){
    return {
      lang: 'EN'
    }
  }
});
</script>
<style>



</style>